import React from "react";

import CRD_SIGNIN from './crd_signin/crd_signin'


export default function SIGNIN() {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <CRD_SIGNIN />
        </>
    );

}