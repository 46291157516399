
/* eslint-disable import/no-anonymous-default-export */

export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: ""
    },
    apiGateway_approvals: {
        REGION: "us-east-1",
        URL: "https://3ipbg8gmz5.execute-api.us-east-1.amazonaws.com/KBAOPS-API/"
    },
    apiGateway_documents: {
        REGION: "us-east-1",
        URL: "https://0ez9ylrkfd.execute-api.us-east-1.amazonaws.com/KBAOPS-API/"
    },
    apiGateway_reports: {
        REGION: "us-east-1",
        URL: "https://n6er05ba56.execute-api.us-east-1.amazonaws.com/KBAOPS-API/"
    },
    apiGateway_employees: {
        REGION: "us-east-1",
        URL: "https://1gc0xxlu28.execute-api.us-east-1.amazonaws.com/KBAOPS-API/"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_EdN008JEy",
        APP_CLIENT_ID: "6tfq3rnrlovsprbi7ufb4uak3b",
        IDENTITY_POOL_ID: "us-east-1:d0336a92-fe2a-4a63-84c9-4757cb189bb0"
    }
};