import React from 'react';

import './cmp_processing.css';



export default function PROCESSING({ state }){

    // RENDER ==========================================================================================================

    return (
        <div className={'processing '+ (state)}/>
    );

};