import { Auth } from 'aws-amplify';


const Authentication = {


    clear: function() {
        sessionStorage.removeItem('authenticated')
    },

    current_session: function(){
        if (sessionStorage.getItem('authenticated') && sessionStorage.getItem('authenticated') === 'true') {
            console.log('true')
            return true;
        } else {
            console.log('false')
            return false;
        }
    },

    current_user: async function(){
        try {
            await Auth.currentUserInfo()
            return true;
        } catch (error){
            return false;
        }
    },

    is_signed_in: async function(var_authenticated){
        if (var_authenticated) {
            return true;
        } else {
            return false;
        }
    },

    password_new: async function(var_authentication, var_password) {
        await Auth.completeNewPassword(
            var_authentication,
            var_password.trim()
        )
    },

    sign_in: async function(var_username, var_password){
        return await Auth.signIn(var_username.trim().toLowerCase(), var_password.trim());
    },

    sign_out: async function(navigate){
        sessionStorage.removeItem('authenticated');
        await Auth.signOut();
        this.clear();
        if (navigate) {
            navigate('/');
        }
    },

    // signed_in: function(var_cognitosub){
    //     sessionStorage.setItem('authenticated', true)
    // }

}

export default Authentication;