import React from 'react';
import { Route, Routes, } from "react-router-dom";
import PRIVATE from 'components/cmp_routes/cmp_private'
import PUBLIC from 'components/cmp_routes/cmp_public'

// public
import SIGNIN from './containers/signin/signin'
import NOTFOUND from './containers/misc/notfound'
import HANDBOOK from './containers/handbook/handbook'

export default function Links({ appProps }) {

        return (
            <Routes>
                    <Route path="/" element={<PUBLIC> <SIGNIN/> </PUBLIC> } />
                    <Route path="/handbook" element={ <PUBLIC> <HANDBOOK/> </PUBLIC>} />
                    <Route path="*" element={<NOTFOUND/>} />;
            </Routes>
        );
}
