import { API as AWS_API } from 'aws-amplify';

const API = {

    get(service, path, init) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return AWS_API.get(service, path, init);
    },

    put(service, path, init) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return AWS_API.put(service, path, init);
    },

    post(service, path, init) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return AWS_API.post(service, path, init);
    },

    del(service, path, init) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return AWS_API.del(service, path, init);
    }

}

export default API;