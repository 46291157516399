import React from 'react';
import ICON from 'components/cmp_icon/cmp_icon'

import './cmp_message.css';



export default function MESSAGE({ header, state, text }){

    // RENDER ==========================================================================================================

    return (
        <>
        {state &&
            <div className={'message ' + (state)}>
                <ICON name='warning' className='message__icon' alt='warning icon'/>
                <div className='message__text__wrapper'>
                    <div className='message__text__header medium'>{header}</div>
                    <div className='text--sm normal'>{text}</div>
                </div>
            </div>
        }
        </>
    );

};