import React from "react";



export default function DOMAINS() {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER CONTAINER ================================================================================================

    return (
        <div>
            <div className='text--md medium'>404 PAGE NOT FOUND</div>
            <div className='text--base normal'>You've clearly done something wrong</div>
        </div>
    )
};