import React from "react";
import { Navigate } from "react-router-dom";



export default function PUBLIC({ children }) {

    if (sessionStorage.getItem('authenticated') === 'true') {
        return <Navigate to={`/domains`} />;
    } else {
        return children;
    }

}