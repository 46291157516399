import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";

import './cmp_item.css';
import {Modal} from "semantic-ui-react";



export default function CMP_ITEM({ id, onOpen, onClose }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const [var_item, set_item] = useState([]);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (onOpen && id) {
            set_item({});
            populate_item();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onOpen, id ]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_item(){
        try {
            let result = await API_get_item();
            set_item(result[0]);
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_item(){
        return API.get('kbaops_documents', 'get-handbook-item/'+id);
    }



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER CMP ======================================================================================================

    return (
        <Modal
            closeOnEscape={true}
            closeOnDimmerClick={true}
            dimmer={'inverted'}
            id="cmp_item"
            open={onOpen}
            onClose={onClose}>
            <div className="modal__content">
                <div className="cmp_item--text text__base" dangerouslySetInnerHTML={ { __html: var_item.item_text}}/>
            </div>
        </Modal>
    )

}