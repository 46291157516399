import React from 'react';
import config from './config';
import { Amplify } from 'aws-amplify';
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';



//  variable declarations ------------------------------------------------------------------------------------------

const container = document.getElementById("root");
const root = createRoot(container);



//  app configuration ----------------------------------------------------------------------------------------------

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
        endpoints: [
            {
                name: "kbaops_approvals",
                endpoint: config.apiGateway_approvals.URL,
                region: config.apiGateway_approvals.REGION
            },
            {
                name: "kbaops_documents",
                endpoint: config.apiGateway_documents.URL,
                region: config.apiGateway_documents.REGION
            },
            {
                name: "kbaops_reports",
                endpoint: config.apiGateway_reports.URL,
                region: config.apiGateway_reports.REGION
            },
            {
                name: "kbaops_employees",
                endpoint: config.apiGateway_employees.URL,
                region: config.apiGateway_employees.REGION
            }
        ]
    }
});



// RENDER APP ======================================================================================================

root.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>
);
