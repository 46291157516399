import React, { useContext, useState } from "react";
import Authentication from 'libs/authentication';
import API from 'libs/api';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { INPUT } from 'components/cmp_form/cmp_form';
import PROCESSING from 'components/cmp_processing/cmp_processing';
import MESSAGE from 'components/cmp_message/cmp_message';

import './crd_signin.css';



export default function CRD_SIGNIN() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const navigate = useNavigate();

    const [ var_can_process, set_can_process ] = useState(true);
    const [ var_processing, set_processing ] = useState('inactive');
    const [ var_message, set_message ] = useState({});

    const [ var_signin, set_signin ] = useState({})



    //  event listeners ------------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------

    async function authenticate(){
        try {
            set_can_process(false);
            set_processing('processing');

            //  authenticate with cognito
            let var_signin_result = await Authentication.sign_in(var_signin.email, var_signin.password);

            //  set password on initial login
            if (var_signin_result.challengeName === 'NEW_PASSWORD_REQUIRED') {
                await Authentication.password_new(var_signin_result, var_signin.password)
            }

            //  get user details
            let var_authenticateduser = await API_get_authenticateduser();

            //  check if account is active
            if (var_authenticateduser === 'NO ACTIVE ACCOUNT') {
                let message = {...var_message}
                message.state = 'warning'
                message.header = 'There was a problem signing in'
                message.text = 'An unexpected error occurred.  Please try again'
                set_message(message);
                set_processing('error');

                await Authentication.sign_out();
                return;
            }

            //  set authenticated status in session storage
            sessionStorage.setItem('authenticated','true');

            set_processing('inactive');
            navigate('/domains');

        } catch (error) {

            if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
                let message = {...var_message}
                message.state = 'warning'
                message.header = 'There was a problem signing in'
                message.text = "We couldn't find a match in our records for the email and password you entered.  Please try again."
                set_message(message);
            }else{
                let message = {...var_message}
                message.state = 'warning'
                message.header = 'There was a problem signing in'
                message.text = 'An unexpected error occurred.  Please try again'
                set_message(message);
            }

            sessionStorage.removeItem('authenticated');
            set_processing('error');

            return;

        }

        set_processing('inactive');
        set_can_process(true);

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_authenticateduser() {
        return API.get('admin', '/get-authenticateduser');
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event){
        let signin = { ...var_signin };
        signin[event.target.name] = event.target.value;
        set_signin(signin);
        set_message({});
        set_processing('inactive');
        set_can_process(true);
    }

    function onClick_signin(){

        //  form validation

        if (var_can_process) {
            authenticate();
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <div id='mdl_signin' className='modal rounded-lg shadow-sm'>
            <div className='modal__header'>
                <div className='text--md medium'>Lively Village Admin</div>
            </div>

            <div className='modal__content'>

                <MESSAGE
                    header={var_message.header}
                    state={var_message.state}
                    text={var_message.text}
                />

                <div className='modal__content__instructions text--sm normal color--gray--600'>Enter your Lively Village administrative username and password.</div>

                <INPUT
                    errors={[]}
                    label='Email address'
                    name='email'
                    onChange={onChange_input}
                    placeholder='Email address'
                    value={var_signin.email || ''}
                />

                <INPUT
                    errors={[]}
                    label='Password'
                    name='password'
                    onChange={onChange_input}
                    placeholder='Password'
                    type='password'
                    value={var_signin.password || ''}
                />

            </div>

            <PROCESSING state={var_processing} />
            <div className='modal__footer'>
                <Button className={'primary' + (!var_can_process ? ' inactive' : '')} onClick={onClick_signin}>SIGN IN</Button>
            </div>

        </div>
    );

}