import React, { useEffect, useState } from 'react';
import Authentication from 'libs/authentication';
import API from "libs/api";
import { useNavigate } from 'react-router-dom';
import Routes from 'routes';

import 'ui_app.css';


function App() {

  //  variable declarations --------------------------------------------------------------------------------------------

  const navigate = useNavigate();

  const [ var_authenticateduser, set_authenticateduser ] = useState({})
  const [ var_hamburgerstatus, set_hamburgerstatus ] = useState('CLOSED')



  //  event listeners ------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (sessionStorage.getItem('authenticated') === 'true') {
      onLoad_checkauthentication();
    }
    // eslint-disable-next-line
  }, [window.location.pathname]);



  //  functions calls --------------------------------------------------------------------------------------------------

  async function onLoad_checkauthentication() {
    try {

      //  get user details
      let var_authenticateduser = await API_get_authenticateduser();

      //  check if account is active
      if (!var_authenticateduser) {
        onClick_signout();
      } else if (var_authenticateduser === 'NO ACTIVE ACCOUNT') {
        onClick_signout();
        alert('NO ACTIVE ACCOUNT');
      } else {
        set_authenticateduser(var_authenticateduser)
      }

    } catch (error) {
      console.log(error);
    }
  }



  //  API calls --------------------------------------------------------------------------------------------------------

  function API_get_authenticateduser() {
    return API.get('admin', '/get-authenticateduser');
  }



  //  event functions --------------------------------------------------------------------------------------------------

  async function onClick_signout() {
    await Authentication.sign_out();
    set_hamburgerstatus('CLOSED');
    navigate('/');
  }

  function onClick_hamburger(status){
    set_hamburgerstatus(status);
  }

  function onClick_menu(var_path){
    navigate(var_path);
  }



  // RENDER APP ========================================================================================================

  return (
      <>
        {sessionStorage.getItem('authenticated') === 'true' &&
            <div className='private'>
              <div className='navigation'>
                <div className='navigation__header'>
                  <div className='navigation__logo'>LV logo</div>
                  <div className='navigation__hamburger'>
                    {var_hamburgerstatus === 'CLOSED' ?
                        <div onClick={() => onClick_hamburger('OPEN')}>OPEN</div>
                        :
                        <div onClick={() => onClick_hamburger('CLOSED')}>CLOSE</div>
                    }
                  </div>
                </div>
                <div className={'navigation__hamburger__wrapper' + (var_hamburgerstatus === 'OPEN' ? ' hamburgerstatus--open' : ' hamburgerstatus--closed')}>
                  <div className='navigation__menu'>
                    <div className='navigation__menu__item' onClick={() => onClick_menu('/organizations')}>Organizations</div>
                    <div className='navigation__menu__item' onClick={() => onClick_menu('/domains')}>Domains</div>
                    <div className='navigation__menu__item' onClick={() => onClick_menu('/tags')}>Tags</div>
                    <div className='navigation__menu__item' onClick={() => onClick_menu('/searches')}>Searches</div>
                  </div>
                  <div className='navigation__user' onClick={onClick_signout}>
                    <div>{ var_authenticateduser.firstname } { var_authenticateduser.lastname }</div>
                    <div className='navigation__user__signout text--sm' >sign out</div>
                  </div>
                </div>
              </div>
            </div>
        }

        <div className={'content ' + (sessionStorage.getItem('authenticated') !== 'true' ? 'public' : 'private')}>
          <Routes />
        </div>

      </>
  );

}

export default App;
