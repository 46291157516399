import React from 'react';
import { Input, Form } from 'semantic-ui-react';



export default function INPUT({ action, description, disabled, errors, icon, label, maxLength, name, onChange, placeholder, type , value }) {

    //  event functions ------------------------------------------------------------------------------------------------
    let error = errors.find(item => item.name === name);



    // RENDER ==========================================================================================================
    return (
        <div className='field__wrapper'>
            <div className='field__label text--sm bold'>{label}</div>
            <Form.Field>
                <Input
                    action={action || null}
                    disabled={disabled || false}
                    error={error !== undefined}
                    maxLength={maxLength || 100}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder || ''}
                    type={type === 'password' ? 'password' : 'text'}
                    value={value || ''}
                    icon={icon}
                />
                {error && <div className='field__error text--sm-regular'>{error.description}</div>}
                {description && <div className='field__description text--sm italic color--gray--500'>{description}</div>}
            </Form.Field>
        </div>
    );

};